import React from 'react';

import { getYoutubeId } from '@dop/shared/helpers/youtubeHelper';
import { EmbedYouTube } from 'moduleAlias/project/src/components/display/embedYoutube';
import { Embed } from 'moduleAlias/project/src/components/embed';

import { MarkupError } from './MarkupError';
import { MarkupElementProps } from '../markupResolvers';

// Returns an embedded iframe with a video
export const MarkupEmbedOrYoutube = ({
	node,
}: MarkupElementProps): React.ReactElement | null => {
	const src = node.attributes?.src;
	const title = node.attributes?.title;

	if (src == null) {
		return (
			<MarkupError>{'IFrame is missing required attribute: src'}</MarkupError>
		);
	}

	const youtubeId = getYoutubeId(src);

	if (youtubeId == null) {
		// not a youtube video
		return <Embed src={src} title={title} {...node.attributes} />;
	}

	return (
		<span
			css={`
				display: block;
				margin-block-end: 1rem;
			`}
		>
			<EmbedYouTube youtubeId={youtubeId} src={src} title={title} />
		</span>
	);
};

import React from 'react';

import { IconCheckBullet } from '@dop/ui-icons/bullets/IconCheckBullet';
import { IconMinusBullet } from '@dop/ui-icons/bullets/IconMinusBullet';
import { IconPlusBullet } from '@dop/ui-icons/bullets/IconPlusBullet';
import {
	Columns,
	ColumnsItem,
	ColumnsProps,
} from '@dop/ui-primitives/layout/Columns';
import { ChevronLink } from '@dop/ui-composites/link/ChevronLink';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { Occupy } from '@dop/ui-primitives/layout/Occupy';
import { MarginTrimTextBlock } from '@dop/ui-composites/typography/MarginTrim';
import { ColumnsListItem } from '@dop/ui-composites/list/ListItem';

import { MarkupElementProps } from './markupResolvers';
import { renderNodes } from './Markup';
import { ValidatedElementNode } from './markupValidate';
import { mergeElementRule } from './markupRulesFunctions';
import { findNode } from './markupNode.helpers';
import { MarkupError } from './markupElements/MarkupError';

const specialListIcons = {
	checklist: IconCheckBullet,
	'pros-list': IconPlusBullet,
	'cons-list': IconMinusBullet,
};

const isSpecialListIconKey = (
	listType: string | undefined
): listType is keyof typeof specialListIcons => {
	return listType != null && Object.keys(specialListIcons).includes(listType);
};

const specialListTypes = ['link-list', ...Object.keys(specialListIcons)];

export const isSpecialList = (node: ValidatedElementNode) => {
	const dataDopListType = node.attributes?.['data-dop-list'];

	return (
		(node.tag === 'ul' &&
			dataDopListType != null &&
			specialListTypes.includes(dataDopListType)) ||
		(['ul', 'ol'].includes(node.tag) &&
			node.attributes?.['data-dop-list-columns'] === '2')
	);
};

const MarkupColumns = ({
	node,
	...props
}: Pick<MarkupElementProps, 'node'> & ColumnsProps) => {
	const columnCount =
		node.attributes?.['data-dop-list-columns'] === '2' ? 2 : 1;

	return (
		<div
			css={`
				margin-block: 1rem;
			`}
		>
			<Columns
				as={node.tag as keyof JSX.IntrinsicElements}
				$count={columnCount}
				$gap={[2, 1]}
				{...props}
			/>
		</div>
	);
};

const MarkupLinkList = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	return (
		<MarkupColumns node={node}>
			{node.children?.map((li, index) => {
				if (!('tag' in li) || li.tag !== 'li') return null;

				const linkNode = findNode(li, ancestorRules, ({ tag }) => tag === 'a');

				const markupRule = mergeElementRule(ancestorRules, linkNode);

				return (
					<ColumnsItem as="li" key={index}>
						{linkNode == null || !linkNode.attributes?.href ? (
							<MarkupError>{'No link found in this list item'}</MarkupError>
						) : (
							<ChevronLink
								href={linkNode.attributes?.href}
								linkType="contentLink"
							>
								{renderNodes(linkNode.children, markupRule, markupResolvers)}
							</ChevronLink>
						)}
					</ColumnsItem>
				);
			})}
		</MarkupColumns>
	);
};

const MarkupIconList = ({
	node,
	ancestorRules,
	markupResolvers,
	listType,
}: MarkupElementProps & { listType: keyof typeof specialListIcons }) => {
	const Icon = specialListIcons[listType];
	return (
		<MarkupColumns node={node}>
			{node.children?.map((li, index) => {
				if (!('tag' in li) || li.tag !== 'li') return null;

				const markupRule = mergeElementRule(ancestorRules, li);

				return (
					<ColumnsItem as="li" key={index}>
						<Shelf $gap={1}>
							<Occupy
								$inlineSize={2}
								$justifyItems="end"
								$blockSize={'var(--cap-height)'}
							>
								<Svg as={Icon} $blockSize={'calc(1.2 * var(--cap-height))'} />
							</Occupy>
							<MarginTrimTextBlock>
								{renderNodes(li.children, markupRule, markupResolvers)}
							</MarginTrimTextBlock>
						</Shelf>
					</ColumnsItem>
				);
			})}
		</MarkupColumns>
	);
};

const MarkupTwoColumnList = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	const { tag } = node;
	if (tag !== 'ul' && tag !== 'ol') return null;

	return (
		<MarkupColumns node={node} $listStyleType="revert">
			{node.children?.map((li, index) => {
				if (!('tag' in li) || li.tag !== 'li') return null;

				const markupRule = mergeElementRule(ancestorRules, li);

				return (
					<ColumnsListItem key={index}>
						<MarginTrimTextBlock>
							{renderNodes(li.children, markupRule, markupResolvers)}
						</MarginTrimTextBlock>
					</ColumnsListItem>
				);
			})}
		</MarkupColumns>
	);
};

export type MarkupSpecialListProps = MarkupElementProps;

export const MarkupSpecialList = (props: MarkupSpecialListProps) => {
	const listType = props.node.attributes?.['data-dop-list'];

	if (listType === 'link-list') {
		return <MarkupLinkList {...props} />;
	}

	if (isSpecialListIconKey(listType)) {
		return <MarkupIconList listType={listType} {...props} />;
	}

	if (props.node.attributes?.['data-dop-list-columns'] === '2') {
		return <MarkupTwoColumnList {...props} />;
	}

	return null;
};

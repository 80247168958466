import React from 'react';

import {
	EmbedContainer,
	EmbedContainerMedia,
	EmbedContainerMediaWrapper,
} from './embedStyled';

export const Embed = ({ src, title, ...props } = {}) => {
	return (
		<EmbedContainer {...props}>
			<EmbedContainerMediaWrapper>
				<EmbedContainerMedia src={src} title={title} allowFullScreen={true} />
			</EmbedContainerMediaWrapper>
		</EmbedContainer>
	);
};

import React from 'react';
import styled from 'styled-components';

import { debounce } from '@dop/shared/helpers/functional';
import { getWindow } from '@dop/shared/helpers/windowDocument';
import { useIntersectionObserver } from '@dop/shared/hooks/useIntersectionObserver';
import { zIndexSideScroll } from '@dop/shared/constants/zIndex';

const SideScrollArea = styled.div`
	overflow: hidden;
	scroll-behavior: smooth;
	display: flex;
`;
const SideScrollContent = styled.div`
	flex: none;
	min-width: 100%;
`;

const SideScrollWrapper = styled.div`
	position: relative;
	z-index: ${zIndexSideScroll};
`;

const Pixel = styled.span`
	display: block;
	width: 1px;
	height: 1px;
	flex: none;
	pointer-events: none;
`;

const getNewScrollPos = ({
	scrollElement,
	scrollMax,
	direction = 1,
	scrollRatio,
	minScrollDelta,
}) => {
	const wantedScrollDelta = scrollElement.clientWidth * scrollRatio;
	const maxScrollDelta = scrollElement.clientWidth;
	const scrollDelta = Math.min(
		maxScrollDelta,
		Math.max(minScrollDelta, wantedScrollDelta)
	);

	const newScrollPosWithoutBounds =
		scrollElement.scrollLeft + direction * scrollDelta;
	const newScrollPos = Math.round(
		Math.max(0, Math.min(newScrollPosWithoutBounds, scrollMax))
	);

	return newScrollPos;
};

const uiSideScroll = 'uiSidescroll';
const uiScrollToRightButton = 'uiScrollToRightButton';
const uiScrollToLeftButton = 'uiScrollToLeftButton';

const scrollDebounceDelay = 200;
export const SideScroll = ({
	children,
	className,
	scrollAreaId,
	ButtonContainer = 'div',
	ButtonLeft,
	ButtonRight,
	scrollAreaCss,
	scrollRatio = 0.667,
	minScrollDelta = 400,
}) => {
	const scrollAreaRef = React.useRef(null);
	const scrollContentRef = React.useRef(null);
	const [leftOverflowing, setLeftOverflowing] = React.useState(false);
	const [rightOverflowing, setRightOverflowing] = React.useState(false);
	const [scrollLeft, setScrollLeft] = React.useState(0);
	const [scrollMax, setScrollMax] = React.useState(0);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleScroll = React.useCallback(
		debounce(() => {
			if (!scrollAreaRef.current) return;
			setScrollLeft(scrollAreaRef.current.scrollLeft);
		}, scrollDebounceDelay),
		[]
	);

	React.useEffect(() => {
		const win = getWindow();
		if (!win) return undefined;
		if (!('IntersectionObserver' in win)) return undefined;
		if (!scrollAreaRef.current || !scrollContentRef.current) return undefined;

		const resizeObserver = new ResizeObserver(() => {
			setScrollMax(
				scrollAreaRef.current.scrollWidth - scrollAreaRef.current.clientWidth
			);
		});
		resizeObserver.observe(scrollAreaRef.current);
		resizeObserver.observe(scrollContentRef.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const beforeElementRef = useIntersectionObserver(
		(isIntersecting) => {
			setLeftOverflowing(!isIntersecting);
		},
		{ root: scrollAreaRef.current },
		true
	);
	const afterElementRef = useIntersectionObserver(
		(isIntersecting) => {
			setRightOverflowing(!isIntersecting);
		},
		{ root: scrollAreaRef.current },
		true
	);
	return (
		<SideScrollWrapper className={className} data-ui-test={uiSideScroll}>
			<ButtonContainer
				role="scrollbar"
				aria-controls={scrollAreaId}
				aria-orientation="horizontal"
				aria-valuemin={0}
				aria-valuenow={scrollLeft}
				aria-valuemax={scrollMax}
			>
				{leftOverflowing && (
					<ButtonLeft
						aria-label={'scroll naar links'}
						data-ui-test={uiScrollToLeftButton}
						type="button"
						onClick={() => {
							if (scrollAreaRef.current) {
								const newScrollPos = getNewScrollPos({
									scrollElement: scrollAreaRef.current,
									scrollMax,
									direction: -1,
									scrollRatio,
									minScrollDelta,
								});
								scrollAreaRef.current.scrollLeft = newScrollPos;
							}
						}}
					/>
				)}
				{rightOverflowing && (
					<ButtonRight
						aria-label={'scroll naar rechts'}
						data-ui-test={uiScrollToRightButton}
						onClick={() => {
							if (scrollAreaRef.current) {
								const newScrollPos = getNewScrollPos({
									scrollElement: scrollAreaRef.current,
									scrollMax,
									direction: 1,
									scrollRatio,

									minScrollDelta,
								});
								scrollAreaRef.current.scrollLeft = newScrollPos;
							}
						}}
					/>
				)}
			</ButtonContainer>
			<SideScrollArea
				css={scrollAreaCss}
				ref={scrollAreaRef}
				id={scrollAreaId}
				onScroll={handleScroll}
			>
				<Pixel
					ref={beforeElementRef}
					css={`
						margin-right: -1px;
					`}
				/>
				<SideScrollContent ref={scrollContentRef}>{children}</SideScrollContent>
				<Pixel
					ref={afterElementRef}
					css={`
						margin-left: -1px;
					`}
				/>
			</SideScrollArea>
		</SideScrollWrapper>
	);
};

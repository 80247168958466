import React from 'react';

import { ContentLinkInline } from '@dop/ui-composites/link/ContentLinkInline';
import { renderNodes } from '@dop/shared/components/markup/Markup';
import { LinkInline } from '@dop/ui-composites/link/LinkInline';

import { MarkupError } from './MarkupError';
import { MarkupElementProps } from '../markupResolvers';
import { mergeElementRule } from '../markupRulesFunctions';

// Renders an inline-link with default styling (and possible external link icon)
export const MarkupInlineLink = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps): React.ReactElement | null => {
	const href = node.attributes?.href;

	if (href == null) {
		return (
			<MarkupError>{'Link is missing required attribute: href'}</MarkupError>
		);
	}

	const markupRule = mergeElementRule(ancestorRules, node);

	if (
		node.children != null &&
		node.children.length === 1 &&
		'tag' in node.children[0] &&
		node.children[0].tag === 'img'
	) {
		// in case the child of a link is a single image, make it a link-inline without any styling, span-wrap or external-link icon
		return (
			<LinkInline {...node.attributes} linkType="contentLink" href={href}>
				{renderNodes(node.children, markupRule, markupResolvers)}
			</LinkInline>
		);
	}

	return (
		<ContentLinkInline {...node.attributes} linkType="contentLink" href={href}>
			{renderNodes(node.children, markupRule, markupResolvers)}
		</ContentLinkInline>
	);
};

import React from 'react';

import { ContentImage } from '@dop/ui-composites/media/ContentImage';

import { MarkupError } from './MarkupError';
import { MarkupElementProps } from '../markupResolvers';

export const MarkupImage = ({
	node,
}: MarkupElementProps): React.ReactElement | null => {
	const src = node.attributes?.src;
	const alt = node.attributes?.alt;

	if (src == null)
		return (
			<MarkupError>{'Image is missing required attribute: src'}</MarkupError>
		);
	if (alt == null)
		return (
			<MarkupError>{'Image is missing required attribute: alt'}</MarkupError>
		);

	return <ContentImage {...node.attributes} src={src} alt={alt} />;
};

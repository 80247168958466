import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconImportantSquare: SvgContent = ({
	cropped = false,
	...props
}) => (
	<svg
		x="0"
		y="0"
		{...(cropped
			? { viewBox: '9.15 9.15 45.7 45.7', width: '45.7', height: '45.7' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					transform="rotate(180 32 32)"
					d={`M53.8564,14.1172c-0.3432-2.0615-1.9116-3.6299-3.9736-3.9736C43.9219,9.1499,33.9868,9.1499,32,9.1499
c-1.9868,0-11.9219,0-17.8828,0.9937c-2.062,0.3437-3.6304,1.9121-3.9736,3.9736C9.1499,20.0781,9.1499,30.0132,9.1499,32
s0,11.9219,0.9937,17.8828c0.3432,2.0615,1.9116,3.6299,3.9736,3.9736c5.9609,0.9937,15.896,0.9937,17.8828,0.9937
c1.9868,0,11.9219,0,17.8828-0.9937c2.062-0.3437,3.6304-1.9121,3.9736-3.9736c0.9937-5.9609,0.9937-15.896,0.9937-17.8828
C54.8501,26.0361,54.8501,20.0781,53.8564,14.1172z M35,48h-6.5645L29,34v-8h6V48z M32.0229,22
C29.7813,22,28.5,20.7466,28.5,18.457c0-1.9448,1.1895-3.457,3.4771-3.457c2.2417,0,3.5229,1.2095,3.5229,3.457
C35.5,20.4443,34.3105,22,32.0229,22z`}
				/>
			</g>
		</g>
	</svg>
);

import React from 'react';

import { IconChevronRight } from '@dop/ui-icons/svg/chevronRight';
import { TextBlockProps } from '@dop/ui-primitives/typography/TextBlock';
import { SvgProps } from '@dop/ui-primitives/media/Svg';

import { LinkBlockProps } from './LinkBlock';
import { ShelfLinkBlock, ShelfLinkIcon, ShelfLinkText } from './ShelfLink';
import { WithLinkTypeIcon } from './WithLinkTypeIcon';

/**
 * A block link with a chevron in front. It is often used in lists.
 */
export const ChevronLink = ({
	children,
	textBlockProps,
	svgProps,
	...props
}: LinkBlockProps & {
	textBlockProps?: TextBlockProps;
	svgProps?: SvgProps;
}) => {
	if (props.href == null) return null;

	return (
		<ShelfLinkBlock {...props}>
			<ShelfLinkIcon as={IconChevronRight} {...svgProps} />
			<ShelfLinkText {...textBlockProps}>
				<WithLinkTypeIcon href={props.href}>{children}</WithLinkTypeIcon>
			</ShelfLinkText>
		</ShelfLinkBlock>
	);
};

//This file handles the way accordions are rendered in the special functions markup function
import React from 'react';

import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { renderNodes } from '@dop/shared/components/markup/Markup';

import { Accordion } from '../../accordion/Accordion';
import { Item as AccordionItem } from '../../accordion/Accordion.types';
import { ValidatedElementNode } from '../markupValidate';
import { getElementRule } from '../markupRulesFunctions';
import { renderTextOnly } from '../markupNode.helpers';
import { MarkupElementProps, MarkupResolvers } from '../markupResolvers';

export const uiAccordionContent = 'uiAccordionContent';

export const hasMarkupLegacyAccordion = (node: ValidatedElementNode): boolean =>
	node.tag === 'ul' && node.attributes?.className === 'acc';

const getAccordionItems = ({
	node,
	markupResolvers,
}: {
	node: ValidatedElementNode;
	markupResolvers: MarkupResolvers;
}) => {
	if (!hasMarkupLegacyAccordion(node)) return [];

	const children = node.children;

	if (children == null || children.length === 0) return [];

	const accordionItems: Array<AccordionItem> = [];

	for (const [index, child] of children.entries()) {
		if (!('tag' in child)) continue;
		if (child.tag !== 'li') continue;
		const childrenRules = getElementRule(child.tag)?.children;
		if (!childrenRules) continue;
		if (child.children == null || child.children.length < 2) continue;

		const [headingNode, ...bodyNodes] = child.children;

		accordionItems.push({
			header: renderTextOnly([headingNode]),
			content: renderNodes(bodyNodes, childrenRules, markupResolvers),
			id: String(index),
		});
	}

	return accordionItems;
};

export const MarkupLegacyAccordion = ({
	node,
	markupResolvers,
}: MarkupElementProps) => {
	const items = getAccordionItems({ node, markupResolvers });

	if (!items || items.length === 0) {
		return null;
	}

	return (
		<Accordion items={items}>
			<Stack $gap={2} {...addTestSelector('uiAccordion')}>
				{items.length > 1 && <Accordion.ToggleAll />}
				<Box>
					{items.map((item) => (
						<Accordion.Item key={item.id} item={item} />
					))}
				</Box>
			</Stack>
		</Accordion>
	);
};

import React from 'react';

import { ScrollInline } from '@dop/ui-composites/positioning/ScrollInline';
import { Grid } from '@dop/ui-primitives/layout/Grid';
import { ContentImage } from '@dop/ui-composites/media/ContentImage';

import { MarkupError } from './MarkupError';
import { MarkupElementProps } from '../markupResolvers';
import { findNode } from '../markupNode.helpers';
import { ValidatedElementNode } from '../markupValidate';

// The full-width image is wrapped in a <div> in Bloomreach to allow for data-attributes
export const hasFullWidthImage = (node: ValidatedElementNode) =>
	node.tag === 'div' && node.attributes?.['data-dop-image'] === 'full-width';

/**
 * This is a div with an data-dop-image='full-width' attribute, which makes it horizontally scrollable when it is larger than the horizontal viewport
 */
export const MarkupImageFullWidth = ({
	node,
	ancestorRules,
}: MarkupElementProps): React.ReactElement | null => {
	const imgNode = findNode(node, ancestorRules, ({ tag }) => tag === 'img');

	// No image? Don't render anything
	if (imgNode == null)
		return (
			<MarkupError>{`Missing img-element child inside ${node.tag}-tag`}</MarkupError>
		);

	const src = imgNode.attributes?.src;
	const alt = imgNode.attributes?.alt;

	if (src == null)
		return (
			<MarkupError>{'Image is missing required attribute: src'}</MarkupError>
		);
	if (alt == null)
		return (
			<MarkupError>{'Image is missing required attribute: alt'}</MarkupError>
		);

	// imitate a <p> with margin (where a regular image would be wrapped in)
	return (
		<Grid
			css={`
				margin-block-end: 1rem;
			`}
		>
			<ScrollInline>
				<ContentImage
					{...node.attributes}
					src={src}
					alt={alt}
					$maxInlineSize={`none`}
				/>
			</ScrollInline>
		</Grid>
	);
};

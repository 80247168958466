import { SvgContent } from '@dop/ui-primitives/media/Svg';
import { translate } from '@dop/shared/translate/translate';
import { roodColorTheme } from '@dop/ui-primitives/colorTheme/globalDefinitions/roodColorTheme';

export const IconMinusBullet: SvgContent = ({ cropped: _, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="24"
		height="24"
		aria-label={translate('con')}
		{...props}
	>
		<path
			fill={`var(--fill-color, ${roodColorTheme.text.primary.normal})`}
			fillRule="evenodd"
			d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm2 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-6.26 1a1 1 0 1 0 0-2H6.26a1 1 0 1 0 0 2h11.48Z"
			clipRule="evenodd"
		/>
	</svg>
);

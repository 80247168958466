import { SvgContent } from '@dop/ui-primitives/media/Svg';
import { translate } from '@dop/shared/translate/translate';
import { colorThemeBase } from '@dop/ui-primitives/colorTheme/globalDefinitions/colorThemeBase';

export const IconCheckBullet: SvgContent = ({ cropped: _, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="24"
		height="24"
		aria-label={translate('check')}
		{...props}
	>
		<path
			fill={`var(--fill-color, ${colorThemeBase.positive.secondary.normal})`}
			fillRule="evenodd"
			d="M12 22c5.523 0 10-4.477 10-10 0-1.28-.24-2.503-.678-3.628l1.342-1.88A11.949 11.949 0 0 1 24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0c2.507 0 4.834.769 6.76 2.083l-1.11 1.665A9.954 9.954 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10ZM23.262 2.127a1 1 0 1 0-1.655-1.123l-9.136 13.464-4.374-5.686A1 1 0 0 0 6.512 10l5.217 6.783a1 1 0 0 0 1.62-.049l9.913-14.608Z"
			clipRule="evenodd"
		/>
	</svg>
);

import React from 'react';

import { renderNodes } from '@dop/shared/components/markup/Markup';
import { MarkupElementProps } from '@dop/shared/components/markup/markupResolvers';
import { Heading } from '@dop/ui-composites/typography/Heading';
import { getIdForH2 } from '@dop/shared/helpers/tableOfContentHelpers';
import { mergeElementRule } from '@dop/shared/components/markup/markupRulesFunctions';

export const MarkupH2 = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	const id = getIdForH2(node);
	const markupRule = mergeElementRule(ancestorRules, node);

	return (
		<Heading
			level={2}
			id={id}
			tabIndex={-1}
			{...node.attributes}
			css={`
				margin-block-start: 2rem;
				margin-block-end: 1rem;
			`}
		>
			{renderNodes(node.children, markupRule, markupResolvers)}
		</Heading>
	);
};

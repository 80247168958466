import React from 'react';
import styled from 'styled-components';

import { Shelf, ShelfProps } from '@dop/ui-primitives/layout/Shelf';
import {
	TextBlock,
	TextBlockProps,
} from '@dop/ui-primitives/typography/TextBlock';
import {
	ColumnsItem,
	ColumnsItemProps,
} from '@dop/ui-primitives/layout/Columns';

// Direct child selector is needed in case of nested lists.
// Otherwise an `<ul>` inside an `<ol>` would get the wrong styling.
// `:before` is used instead of `::marker` because `::marker` cannot
// be properly alligned with CapSize.
const Marker = styled.span`
	*:where(ul > li > :not(ol, ul) > :not(ol, ul) > :not(ol, ul)) > &:before,
	*:where(ul > li > :not(ol, ul)) > &:before {
		content: '•';
	}

	*:where(ol > li > :not(ol, ul) > :not(ol, ul) > :not(ol, ul)) > &:before,
	*:where(ol > li > :not(ol, ul)) > &:before {
		content: counter(orderedList) '.';
	}
`;

export type ListItemProps = ShelfProps & {
	/** Text styling properties for the list marker. */
	$marker?: TextBlockProps;
};

export const ListItemShelft = (props: ShelfProps) => {
	return <Shelf as="li" $gap={1} $alignItems="start" {...props} />;
};

/**
 * Component for items inside a bullet or numbered lists.
 *
 * This component should be used as a direct child of a `ul` or `ol` tag,
 * most likely applied through a `<Stack>` element.
 *
 * The list style is not applied through the `<ul>` or `<ol>`
 * because that causes allignment issues with CapSize.
 *
 * NB. This component should not be used inside `<Columns>`.
 * In that case `ColumnsListItem` should be used.
 */
export const ListItem = ({
	$marker,
	children,
	...shelfProps
}: ListItemProps) => {
	return (
		<ListItemShelft {...shelfProps}>
			<TextBlock
				aria-hidden
				$color={['text', 'primary']}
				$fontWeight="bold"
				//  8. This element has a fixed inline size to prevent
				//  9. jagged alignment once the list reaches multiple digets.
				// 10. The numbering in this comment is an example.
				$inlineSize={2}
				$textAlign="end"
				{...$marker}
			>
				<Marker />
			</TextBlock>
			{children}
		</ListItemShelft>
	);
};

export type ColumnsListItemProps = ListItemProps & {
	$columnsItem?: ColumnsItemProps;
};

/**
 * Component for items inside a bullet or numbered multicolumn lists.
 *
 * This component should be used as a direct child of
 * a `<Columns>` element with the tag set to `ul` or `ol`.
 */
export const ColumnsListItem = ({
	$columnsItem,
	...listItemProps
}: ColumnsListItemProps) => {
	return (
		<ColumnsItem as="li" {...$columnsItem}>
			<ListItem as="span" {...listItemProps} />
		</ColumnsItem>
	);
};

import React from 'react';

import { renderNodes } from '@dop/shared/components/markup/Markup';
import {
	Caption,
	Table,
	Td,
	Th,
} from 'moduleAlias/project/src/components/display/table';

import { MarkupElementProps } from '../markupResolvers';
import { mergeElementRule } from '../markupRulesFunctions';

export const MarkupTable = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	const markupRule = mergeElementRule(ancestorRules, node);
	return (
		<Table {...node.attributes}>
			{renderNodes(node.children, markupRule, markupResolvers)}
		</Table>
	);
};

export const MarkupTh = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	const markupRule = mergeElementRule(ancestorRules, node);
	return (
		<Th {...node.attributes}>
			{renderNodes(node.children, markupRule, markupResolvers)}
		</Th>
	);
};

export const MarkupTd = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	const markupRule = mergeElementRule(ancestorRules, node);
	return (
		<Td {...node.attributes}>
			{renderNodes(node.children, markupRule, markupResolvers)}
		</Td>
	);
};

export const MarkupCaption = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	const markupRule = mergeElementRule(ancestorRules, node);
	return (
		<Caption {...node.attributes}>
			{renderNodes(node.children, markupRule, markupResolvers)}
		</Caption>
	);
};

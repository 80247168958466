import { rood } from '@dop/ui-primitives/color/colors';

import { GlobalColorTheme } from '../ColorTheme.types';
import { colorThemeBase } from './colorThemeBase';

export const roodColorTheme: GlobalColorTheme = {
	name: 'Rood',
	project: 'global',
	negative: {
		...colorThemeBase.negative,
		primary: {
			normal: rood[80],
			hover: rood[110],
			active: rood[140],
		},
	},
	positive: {
		...colorThemeBase.positive,
		primary: {
			normal: rood.base,
			hover: rood[560],
			active: rood[590],
		},
	},
	text: {
		...colorThemeBase.text,
		primary: {
			normal: rood[590],
			hover: rood[650],
			active: rood[710],
		},
	},
};

import { List, Map } from 'immutable';

import { createCbsBlockId } from '@dop/shared/components/pageSections/cbsBlock/cbsBlock';

import { get, has, push } from './immutableHelpersWithArgs';
import { pipe } from './functional';
import { renderTextOnly } from '../components/markup/markupNode.helpers';
import { prefixIdInArticle } from '../components/markup/markupValidate';

// Check if element is an accordion (legacy or otherwise)
// We don't import `hasMarkupLegacyAccordion` since that will screw up builds due to circular dependancies
const isAccordion = (node) =>
	(node.tag === 'ul' && node.attributes?.className === 'acc') || // either legacy-accordion...
	(node.tag === 'div' && node.attributes?.['data-dop-acc'] === 'group'); // ...or new one

export const getHeadersFromContent =
	(results) =>
	(content = List()) =>
		content.reduce((memo, element) => {
			// only take up H2's in the actual text, don't include any from an accordion
			if (isAccordion(element.toJS())) return memo;

			return element.get('tag') === 'h2'
				? memo.push(element)
				: getHeadersFromContent(memo)(element.get('children'));
		}, results);

const getTextListFromChildren = (result = List(), children = List()) =>
	children.reduce(getTextListFromNode, result); //eslint-disable-line no-use-before-define

const getTextListFromNode = (result = List(), node = Map()) =>
	node.has('text')
		? result.push(node.get('text', ''))
		: getTextListFromChildren(result, node.get('children'));

export const getTextFromNode = (node) =>
	getTextListFromNode(List(), node).join('');

export const getTextFromChildren = (children = List()) =>
	children.map(getTextFromNode).join('');

const getTextMapFromNode = (node) =>
	Map({ text: getTextFromNode(node), id: node?.getIn(['attributes', 'id']) });

const getTextFromNodeList = (nodes = List()) => nodes.map(getTextMapFromNode);

export const getIdForH2 = (node) => {
	const nodeId = node.attributes?.id;

	if (nodeId != null && nodeId.length > 0) {
		return nodeId;
	}

	const text = renderTextOnly([node]);
	const id = prefixIdInArticle(text);

	return id;
};

const addIdForH2 = (textNode = Map()) => {
	const nodeId = textNode.get('id');
	if (nodeId != null && nodeId.length > 0) {
		return textNode.set('id', prefixIdInArticle(nodeId));
	}
	return textNode.set('id', prefixIdInArticle(textNode.get('text')));
};

const addIdForH2List = (textNodes = List()) => textNodes.map(addIdForH2);

const getHeaderTextNodes = pipe(
	getHeadersFromContent(List()),
	getTextFromNodeList,
	addIdForH2List
);

export const getJumpList = ({ content, cbsBlock }) => {
	const jumpList = getHeaderTextNodes(content).concat();

	if (has('title')(cbsBlock)) {
		const title = get('title')(cbsBlock);
		const cbsId = createCbsBlockId(title);

		const cbsJumpElement = Map({
			id: cbsId,
			text: title,
		});

		return push(cbsJumpElement)(jumpList);
	}

	return jumpList;
};

/**
 * Find duplicate ID’s in jumpList but don’t mark the first occurance as duplicate.
 */
export const isDuplicateID = (jumpList, id, index) => {
	return jumpList.slice(0, index).some((item) => {
		return id === item.id;
	});
};

import React from 'react';

import { splitOnNewlines } from '@dop/shared/helpers/string';
import { Heading } from 'moduleAlias/project/src/components/display/heading';
import { useCbsBlock } from '@dop/shared/hooks/useCbsBlock';
import { EmbedIFrame } from 'moduleAlias/project/src/components/display/embedIFrame';

import { prefixIdInArticle } from '../../markup/markupValidate';

export const createCbsBlockId = (title = 'cbsBlock') =>
	prefixIdInArticle(title);

// if anything (title, description or iframeSrc) is set, it should render
export const shouldRenderCbsBlock = ({ title, description, iframeSrc } = {}) =>
	title != null || description != null || iframeSrc != null;

// We use a separate CbsBlock with props to directly set the iframe props, instead of via the useCbsBlock hook.
// This is used in the 'landenFactsFiguresGraph'
export const CbsBlockWithProps = ({
	headingLevel = 2,
	headingStyleLevel,
	title,
	description,
	iframeSrc,
}) => {
	if (!shouldRenderCbsBlock({ title, description, iframeSrc })) return null;

	const paragraphs = splitOnNewlines(description);

	return (
		<div id={createCbsBlockId(title)}>
			<Heading
				level={headingLevel}
				styleLevel={headingStyleLevel}
				tabIndex="-1"
			>
				{title}
			</Heading>
			{paragraphs.map((paragraph, index) => (
				<p key={index}>{paragraph}</p>
			))}
			<EmbedIFrame src={iframeSrc} title={title} aspectRatio={1.4} />
		</div>
	);
};

export const CbsBlock = ({ headingLevel = 2, headingStyleLevel }) => {
	const { title, description, iframeSrc } = useCbsBlock();

	return (
		<CbsBlockWithProps
			headingLevel={headingLevel}
			headingStyleLevel={headingStyleLevel}
			title={title}
			description={description}
			iframeSrc={iframeSrc}
		/>
	);
};

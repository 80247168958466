import {
	hasMarkupDownloadButton,
	MarkupDownloadButton,
} from './markupElements/MarkupDownloadButton';
import {
	hasMarkupAccordion,
	MarkupAccordion,
} from './markupElements/MarkupAccordion';
import { MarkupEmbedOrYoutube } from './markupElements/MarkupEmbedOrYoutube';
import { MarkupH2 } from './markupElements/MarkupH2';
import { MarkupImage } from './markupElements/MarkupImage';
import {
	hasFullWidthImage,
	MarkupImageFullWidth,
} from './markupElements/MarkupImageFullWidth';
import { MarkupInlineLink } from './markupElements/MarkupInlineLink';
import {
	hasMarkupLegacyAccordion,
	MarkupLegacyAccordion,
} from './markupElements/MarkupLegacyAccordion';
import { MarkupRegularElement } from './markupElements/MarkupRegularElement';
import {
	MarkupCaption,
	MarkupTable,
	MarkupTd,
	MarkupTh,
} from './markupElements/MarkupTable';
import {
	hasMarkupTipBlock,
	MarkupTipBlock,
} from './markupElements/MarkupTipBlock';
import {
	MarkupTwoColumns,
	hasMarkupTwoColumns,
} from './markupElements/MarkupTwoColumns';
import { ElementChildrenRules } from './markupRules';
import { isSpecialLink, MarkupSpecialLink } from './MarkupSpecialLink';
import { isSpecialList, MarkupSpecialList } from './MarkupSpecialList';
import { ValidatedElementNode } from './markupValidate';

export type MarkupResolvers = Array<ResolveFunction>;

export type MarkupElementProps = {
	node: ValidatedElementNode;
	ancestorRules: ElementChildrenRules;
	markupResolvers: MarkupResolvers;
};

type ResolveFunction = {
	condition: (node: ValidatedElementNode) => boolean;
	component: (props: MarkupElementProps) => React.ReactElement | null;
};

export const defaultMarkupResolvers: MarkupResolvers = [
	{
		// renders based on accordion-data-attribute
		condition: hasMarkupAccordion,
		component: MarkupAccordion,
	},
	{
		// renders based on accordion classname
		condition: hasMarkupLegacyAccordion,
		component: MarkupLegacyAccordion,
	},
	{
		condition: hasMarkupTwoColumns,
		component: MarkupTwoColumns,
	},
	{
		condition: hasMarkupTipBlock,
		component: MarkupTipBlock,
	},
	{
		condition: hasMarkupDownloadButton,
		component: MarkupDownloadButton,
	},
	{
		condition: isSpecialLink,
		component: MarkupSpecialLink,
	},
	{ condition: hasFullWidthImage, component: MarkupImageFullWidth },
	{ condition: (node) => node.tag === 'a', component: MarkupInlineLink },
	{ condition: (node) => node.tag === 'img', component: MarkupImage },
	{
		condition: (node) => node.tag === 'iframe',
		component: MarkupEmbedOrYoutube,
	},
	{ condition: (node) => node.tag === 'h2', component: MarkupH2 },
	{ condition: (node) => node.tag === 'table', component: MarkupTable },
	{ condition: (node) => node.tag === 'th', component: MarkupTh },
	{ condition: (node) => node.tag === 'td', component: MarkupTd },
	{ condition: (node) => node.tag === 'caption', component: MarkupCaption },
	{
		condition: (node) => node.tag === 'br',
		component: () => null,
	},
	{
		condition: isSpecialList,
		component: MarkupSpecialList,
	},
	// acts as default fallback
	{ condition: () => true, component: MarkupRegularElement },
];

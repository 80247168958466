import React from 'react';

import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { Grid } from '@dop/ui-primitives/layout/Grid';
import { mqWidth880px } from '@dop/ui-primitives/base/mediaQueryDefinitions';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { MarginTrimTextBlock } from '@dop/ui-composites/typography/MarginTrim';

import { ValidatedElementNode } from '../markupValidate';
import { MarkupNode } from '../Markup.types';
import { MarkupElementProps } from '../markupResolvers';
import { renderNodes } from '../Markup';

export const hasMarkupTwoColumns = (node: ValidatedElementNode): boolean =>
	node.tag === 'div' && node.attributes?.['data-dop-section'] === '2-columns';

const MarkupColumn = ({
	ancestorRules,
	markupResolvers,
	node,
}: Omit<MarkupElementProps, 'node'> & { node: MarkupNode }) => {
	if (node == null || !('children' in node)) {
		return null;
	}

	return (
		<MarginTrimTextBlock>
			{renderNodes([node], ancestorRules, markupResolvers)}
		</MarginTrimTextBlock>
	);
};

/**
 * Two-Columns is an custom widget in BRX; it displays two columns on desktop, or stacks them on mobile screens
 * The columns themselves strip any top and bottom margins from their contents, while the two-columns-container adds some block-padding
 * this way, no matter what the content (i.e. <p> or <ul> with a bottom-margin) is, the white-space after the two-columns-container is the same
 */
export const MarkupTwoColumns = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	if (node.children == null || node.children.length === 0) return null;

	return (
		<Box $paddingBlock={2}>
			<Grid
				$columns={'1fr'}
				$gap={3}
				$mq={{ [mqWidth880px.min]: { $columns: '1fr 1fr' } }}
				{...addTestSelector('uiTwoColumnsContent')}
			>
				{node.children.map((child, i) => (
					<MarkupColumn
						key={i}
						ancestorRules={ancestorRules}
						markupResolvers={markupResolvers}
						node={child}
					/>
				))}
			</Grid>
		</Box>
	);
};

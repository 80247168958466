import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';

export type OccupyStaticStyleProps = Omit<
	BaseStaticStyleProps,
	'$blockSize' | '$inlineSize'
> & {
	$alignItems?: CSSProperty.AlignItems;
	$justifyItems?: CSSProperty.JustifyItems;
	$blockSize?: BaseStaticStyleProps['$blockSize'] | 'var(--cap-height)';
	$inlineSize?: BaseStaticStyleProps['$inlineSize'] | 'var(--cap-height)';
};

export type OccupyComponentStyleProps =
	ComponentStyleProps<OccupyStaticStyleProps>;

export const occupyStaticStyleMap: StyleMap<OccupyStaticStyleProps> = {
	...baseStaticStyleMap,
	$blockSize: (blockSize = 'fit-content') =>
		baseStaticStyleMap.$blockSize(blockSize),
	$inlineSize: (inlineSize = 'fit-content') =>
		baseStaticStyleMap.$inlineSize(inlineSize),
	$alignItems: (alignItems = 'center') => css`
		align-items: ${alignItems};
	`,
	$justifyItems: (justifyItems = 'center') => css`
		justify-items: ${justifyItems};
	`,
};

const occupyStyleMap: ComponentStyleMap<OccupyStaticStyleProps> = {
	normal: occupyStaticStyleMap,
};

/**
 * Component to occupy a set amount of space even if it's children are bigger.
 *
 * Responsibilities:
 *
 * 1. Occupy a set amount of space in the inline dimension.
 * 2. Or occupy a set amount of space in the block dimension.
 * 3. Or occupy a set amount of space both dimension dimension.
 * 4. Align content relative to the occupied space.
 */
export const Occupy = styled.span<OccupyComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: grid;
			grid-template-columns: minmax(0, 1fr);
			grid-template-rows: minmax(0, 1fr);

			${getCSSFromStyleProps(occupyStyleMap, props)};
		`;
	}};
`;

export type OccupyProps = StyledComponentPropsWithoutRef<typeof Occupy>;

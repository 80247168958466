import { useSiteDataSelector } from '@/globals/dataProviders/useSiteDataSelector';

import './embedYoutube.css';
import { Youtube } from '@dop/shared/youtube/youtube';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import {
	FilledBoxButton,
	FilledBoxText,
} from '@dop/ui-composites/link/FilledBoxLink';
import { ContentLinkInline } from '@dop/ui-composites/link/ContentLinkInline';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Rows } from '@dop/ui-primitives/layout/Rows';

import {
	EmbedContainer,
	EmbedContainerMediaWrapper,
	EmbedContainerOverlay,
} from '../embedStyled';
import { useCookieConsent } from '@/sections/cookies/useCookieConsent';

export const EmbedYouTube = ({ src, title, youtubeId }) => {
	const cookieAlertContent = useSiteDataSelector((site) => site.cookieAlert);
	const [consentState, setConsentState] = useCookieConsent();
	const showEmbed = consentState === 'all';

	return (
		<EmbedContainer
			css={`
				container: embed-youtube / size;
			`}
		>
			<EmbedContainerMediaWrapper>
				{showEmbed ? (
					<Youtube
						id={`youtube-${youtubeId}`}
						videoId={youtubeId}
						height={'100%'}
						width={'100%'}
						title={title}
					/>
				) : (
					<EmbedContainerOverlay
						{...addTestSelector('uiYoutubeCookieOverlay')}
						className="embedYoutube"
					>
						<Stack $gap={2}>
							<TextBlock>{cookieAlertContent.overlay}</TextBlock>
							<Rows $gap={1} $alignItems="center">
								<FilledBoxButton
									onClick={() => {
										setConsentState('all', 'videobar-select');
									}}
									{...addTestSelector('uiYoutubeCookieAcceptButton')}
								>
									<FilledBoxText>
										{cookieAlertContent.consentButton.text}
									</FilledBoxText>
								</FilledBoxButton>
								<TextBlock>
									{` of `}
									<ContentLinkInline href={src} target="_blank">
										{cookieAlertContent.youtube}
									</ContentLinkInline>
									{`.`}
								</TextBlock>
							</Rows>
							<TextBlock>
								<ContentLinkInline
									linkType="cookieAlertOverlay"
									href={cookieAlertContent.link.href}
								>
									{cookieAlertContent.link.text}
								</ContentLinkInline>
							</TextBlock>
						</Stack>
					</EmbedContainerOverlay>
				)}
			</EmbedContainerMediaWrapper>
		</EmbedContainer>
	);
};

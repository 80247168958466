import { SvgContent } from '@dop/ui-primitives/media/Svg';
import { translate } from '@dop/shared/translate/translate';
import { groen } from '@dop/ui-primitives/color/colors';

export const IconPlusBullet: SvgContent = ({ cropped: _, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="24"
		height="24"
		aria-label={translate('pro')}
		{...props}
	>
		<path
			fill={`var(--fill-color, ${groen.base})`}
			fillRule="evenodd"
			d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0 2c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm0-18.74a1 1 0 0 1 1 1V11h4.74a1 1 0 1 1 0 2H13v4.74a1 1 0 1 1-2 0V13H6.26a1 1 0 1 1 0-2H11V6.26a1 1 0 0 1 1-1Z"
			clipRule="evenodd"
		/>
	</svg>
);

import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconDownload: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '3 2.01 18 19.99', width: '18', height: '19.99' }
			: { viewBox: '0 0 24 24', width: '24', height: '24' })}
		role="img"
		{...props}
	>
		<title>{'Download'}</title>
		<g>
			<path d="M18,17v2H6v-2H3v4c0,0.5527,0.4473,1,1,1h16c0.5527,0,1-0.4473,1-1v-4H18z" />
			<path
				d={`M10.9414,16.4761c0.2568,0.3218,0.6465,0.5093,1.0586,0.5093c0.4121,0,0.8018-0.1875,1.0586-0.5093l5.7822-7.2329
c0.4131-0.5157,0.3936-1.2539-0.0459-1.7476c-0.4394-0.4922-1.1699-0.5991-1.7304-0.248L14,9.1615V3.4196
c0-1.0874-0.9395-1.4111-1.999-1.4111C10.9395,2.0085,10,2.3322,10,3.4196v5.7419L6.9355,7.2476
c-0.5615-0.3501-1.291-0.2432-1.7304,0.248c-0.4395,0.4937-0.459,1.2319-0.0459,1.7476L10.9414,16.4761z`}
			/>
		</g>
	</svg>
);

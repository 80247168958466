import React from 'react';
import { posix as posixPath } from 'path';

import {
	FilledBoxIcon,
	FilledBoxLink,
	FilledBoxText,
} from '@dop/ui-composites/link/FilledBoxLink';
import { Occupy } from '@dop/ui-primitives/layout/Occupy';
import { IconDownload } from '@dop/ui-icons/svg/download';

import { ValidatedElementNode } from '../markupValidate';
import { MarkupError } from './MarkupError';
import { MarkupElementProps } from '../markupResolvers';
import { hasClassName, renderTextOnly } from '../markupNode.helpers';

export const parseDownloadText = (text = '') => {
	const entries = text.split('|');

	const downloadData: Record<string, string | undefined> = {};
	for (const entry of entries) {
		const [rawKey, ...values] = entry.split(':');
		const key = rawKey?.trim().toLowerCase();
		const value = values.join(':').trim();

		if (key !== '' && value !== '') {
			downloadData[key] = value;
		}
	}

	return downloadData;
};

export const hasMarkupDownloadButton = (node: ValidatedElementNode) =>
	hasClassName(node, 'downloadLink');

export const MarkupDownloadButton = ({ node }: MarkupElementProps) => {
	const dlText = renderTextOnly(node.children);
	const href = node.attributes?.href;
	if (href == null)
		return (
			<MarkupError>
				{'Download Button is missing required attribute: href'}
			</MarkupError>
		);

	const fileType = posixPath.extname(href);
	return (
		<FilledBoxLink href={href} linkType={fileType}>
			<Occupy $blockSize={1}>
				<FilledBoxIcon $blockSize={1.5} as={IconDownload} />
			</Occupy>
			<FilledBoxText href={href}>{dlText}</FilledBoxText>
		</FilledBoxLink>
	);
};

import React from 'react';
import styled, { css } from 'styled-components';

import {
	TextBlock,
	TextBlockProps,
} from '@dop/ui-primitives/typography/TextBlock';

export const marginTrimCSS = css`
	/* eat bottom margin */
	&:before {
		content: '';
		display: block;
		pointer-events: none;
		margin-block-start: -10em;
		margin-block-end: calc(10em + var(--text-block-margin-before, -0.4412em));
	}
	/* eat top margin */
	&:after {
		content: '';
		display: block;
		pointer-events: none;
		margin-block-start: calc(10em + var(--text-block-margin-after, -0.4772em));
		margin-block-end: -10em;
	}
`;

export const MarginTrim = styled.span`
	display: block;
	${marginTrimCSS};
`;

export const MarginTrimTextBlock = ({ children, ...props }: TextBlockProps) => {
	return (
		<TextBlock {...props}>
			<MarginTrim>{children}</MarginTrim>
		</TextBlock>
	);
};

import styled from 'styled-components';

import { divideUnit } from '@dop/shared/styleHelpers/unitCalculationHelpers';
import { isNumber } from '@dop/shared/helpers/functional';
import { cssForMqs } from '@dop/shared/styleHelpers/mediaQueries';

const aspectRatioToCssValue = ({ from, to, aspectRatio }) => {
	return {
		from,
		to,
		value: divideUnit('100%', aspectRatio),
	};
};

const paddingBottomAspectRatioForMqs = (aspectRatio) => {
	const valuesForMqs = isNumber(aspectRatio)
		? [aspectRatioToCssValue({ aspectRatio })]
		: aspectRatio.map(aspectRatioToCssValue);

	return cssForMqs('padding-bottom')(...valuesForMqs);
};

export const AspectRatioWrapper = styled.div`
	width: 100%;
	position: relative;
	max-height: 90vh;
	overflow: hidden;

	&:after {
		content: '';
		display: block;
		height: 0;
		${({ $aspectRatio }) => paddingBottomAspectRatioForMqs($aspectRatio)};
	}
`;

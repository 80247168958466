//This file handles the way accordions are rendered in the special functions markup function
import React from 'react';

import { renderNodes } from '@dop/shared/components/markup/Markup';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Box } from '@dop/ui-primitives/planeDivision/Box';

import { Accordion } from '../../accordion/Accordion';
import { Item as AccordionItem } from '../../accordion/Accordion.types';
import { renderTextOnly } from '../markupNode.helpers';
import { getElementRule } from '../markupRulesFunctions';
import { ValidatedElementNode } from '../markupValidate';
import { MarkupNode } from '../Markup.types';
import { MarkupElementProps, MarkupResolvers } from '../markupResolvers';

export const uiAccordionContent = 'uiAccordionContent';

export const hasMarkupAccordion = (node: ValidatedElementNode): boolean =>
	node.tag === 'div' && node.attributes?.['data-dop-acc'] === 'group';

/**
 * Get both the heading and the body node.
 *
 * If one or both are missing we cannot render an accordion item.
 */
const getAccordionItemNodes = (detailsNode: MarkupNode) => {
	if (!('tag' in detailsNode)) return undefined;
	if (detailsNode.tag !== 'details') return undefined;

	// We need two nodes, one for the heading and one for the body
	// Otherwise we cannot render the accrdion item
	if (detailsNode.children == null || detailsNode.children.length < 2)
		return undefined;

	const headingNode = detailsNode.children.find((child) => {
		return 'tag' in child && child.tag === 'summary';
	});

	// Check the tag again so typescript can narrow the type
	if (headingNode == null || !('tag' in headingNode)) return undefined;

	const bodyNode = detailsNode.children.find((child) => {
		return 'tag' in child && child.attributes?.['data-dop-acc'] === 'content';
	});

	// Check the tag again so typescript can narrow the type
	if (bodyNode == null || !('tag' in bodyNode)) return undefined;

	return {
		headingNode,
		bodyNode,
	};
};

const getAccordionItems = ({
	node,
	markupResolvers,
}: {
	node: ValidatedElementNode;
	markupResolvers: MarkupResolvers;
}) => {
	if (!hasMarkupAccordion(node)) return [];

	const children = node.children;

	if (children == null || children.length === 0) return [];

	const accordionItems: Array<AccordionItem> = [];

	for (const [index, child] of children.entries()) {
		const childrenRules = getElementRule('div')?.children;
		if (!childrenRules) return undefined;

		const itemNodes = getAccordionItemNodes(child);

		if (itemNodes == null) continue;

		const { headingNode, bodyNode } = itemNodes;

		accordionItems.push({
			header: renderTextOnly([headingNode]),
			content: renderNodes(bodyNode.children, childrenRules, markupResolvers),
			id: String(index),
		});
	}

	return accordionItems;
};

export const MarkupAccordion = ({
	node,
	markupResolvers,
}: MarkupElementProps) => {
	const items = getAccordionItems({ node, markupResolvers });

	if (!items || items.length === 0) {
		return null;
	}

	return (
		<Accordion items={items}>
			<Stack $gap={2} {...addTestSelector('uiAccordion')}>
				{items.length > 1 && <Accordion.ToggleAll />}
				<Box>
					{items.map((item) => (
						<Accordion.Item key={item.id} item={item} />
					))}
				</Box>
			</Stack>
		</Accordion>
	);
};

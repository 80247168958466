import { css } from 'styled-components';

export const focusStyle = css`
	outline-color: hsl(217, 99%, 65%);
	outline-style: solid;
	outline-width: 2px;
`;

export const noFocusStyle = css`
	outline: none;
`;

import React from 'react';
import styled from 'styled-components';

import { AspectRatioWrapper } from './aspectRatioWrapper';

const IFrame = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const EmbedIFrame = (props) => {
	const { aspectRatio = 1, src, title, ...rest } = props;

	// Title is required for accessibility
	if (!src || !title) return null;

	return (
		<AspectRatioWrapper $aspectRatio={aspectRatio}>
			<IFrame {...rest} src={src} title={title} />
		</AspectRatioWrapper>
	);
};

import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconRegelhulp: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '2 3.08 34.31 36.66', width: '34.31', height: '36.66' }
			: { viewBox: '0 0 37 42', width: '37', height: '42' })}
		{...props}
	>
		<g>
			<g>
				<path d="M18.0620104,15.2356126 L19.9236008,18.352223 C20.0021873,18.4837043 20.1437433,18.5644954 20.2965101,18.564943 C20.2971794,18.564943 20.2974582,18.564943 20.2981275,18.564943 C20.4502808,18.564943 20.5917811,18.4853269 20.6709811,18.3545729 L23.5139264,13.6651104 C23.6311647,13.4718049 23.5821388,13.2207036 23.4010385,13.0860332 C23.2192689,12.9513628 22.9658847,12.9773793 22.815572,13.1459551 L20.2590853,16.0151009 L18.7222706,14.6796985 C18.5597433,14.5387058 18.3198008,14.5368035 18.1559906,14.6752785 C17.991567,14.8136416 17.9519112,15.0510912 18.0620104,15.2356126 Z" />
				<path d="M20.8496864,24.0597006 L23.513902,19.6650545 C23.6311403,19.4718049 23.5821144,19.2207036 23.4010141,19.0860332 C23.2192445,18.9513628 22.9658603,18.9773793 22.8155476,19.1459551 L20.2590608,22.0151009 L18.7222462,20.6796985 C18.5597189,20.5387058 18.3197763,20.5368035 18.1559662,20.6752785 C17.9915425,20.8135856 17.9519425,21.0510353 18.0619859,21.2355567 L18.5484768,22.050023 C19.6316469,22.2501377 20.5108114,23.0321249 20.8496864,24.0597006 Z" />
				<path d="M21,29.8117675 L23.513902,25.6650649 C23.6311403,25.4717594 23.5821144,25.2206581 23.4010141,25.0859877 C23.2193003,24.9513732 22.9658603,24.9773897 22.8155476,25.1459655 L21,27.1835551 L21,29.8117675 Z" />
				<rect x="25" y="15" width="6.69295775" height="1.6784847" />
				<rect x="25" y="21" width="6.69295775" height="1.6784847" />
				<rect x="25" y="27" width="6.69295775" height="1.6784847" />
				<path d="M21,34.974745 L35.1943662,34.974745 C35.8079431,34.974745 36.3098592,34.4709758 36.3098592,33.8557552 L36.3098592,8.1189898 C36.3098592,7.50102768 35.8103972,7 35.1943662,7 L31.8923397,7 C31.8827465,7.70949548 31.8565882,8.44746926 31.6810096,9.28581642 C31.643529,9.46457504 31.5842963,9.63410199 31.5132952,9.7974745 L33.5211268,9.7974745 L33.5211268,32.1772705 L21,32.1772705 L21,34.974745 Z M14,22 L14,8.1189898 C14,7.50102768 14.499462,7 15.115493,7 L18.4175752,7 C18.4271685,7.70949548 18.4533268,8.44741331 18.6288496,9.28570452 C18.6662744,9.46446314 18.7255628,9.63404604 18.7965639,9.7974745 L16.7887324,9.7974745 L16.7887324,22 L14,22 Z" />
				<path d="M21.4288016,11 L25.2500007,11 L29.0712575,11 C29.9082101,11 30.6348764,10.4533116 30.7996136,9.68479255 C30.9959024,8.76863144 30.9901343,7.96103899 30.99994,7.13139353 C31.0049583,6.70537466 30.694461,6.33844654 30.2657751,6.26838583 L29.2129222,6.09637237 C28.6688165,7.06308576 28.0649532,7.62560709 27.8981395,7.75769895 C27.5460541,8.03664123 27.3505729,8.0644054 27.2434014,7.96171755 C27.2494003,7.9528398 27.2532072,7.94350968 27.2589753,7.93457538 C27.7973706,7.42102303 28.1340551,6.70548775 28.1340551,5.91084438 C28.1340551,4.34937834 26.8428063,3.0835357 25.2500007,3.0835357 C23.6571951,3.0835357 22.3659462,4.34937834 22.3659462,5.91084438 C22.3659462,5.91401096 22.3668691,5.91683827 22.3668691,5.92000486 L20.2342262,6.26844238 C19.8055404,6.33844654 19.4949854,6.70543121 19.5000613,7.13145008 C19.5098671,7.96109554 19.504099,8.76863144 19.7003877,9.68484909 C19.8651826,10.4533116 20.591849,11 21.4288016,11 Z M25.2500007,4.63572816 C25.968361,4.63572816 26.5507092,5.20661833 26.5507092,5.91084438 C26.5507092,6.61507042 25.968361,7.18590404 25.2500007,7.18590404 C24.5316404,7.18590404 23.9492921,6.61507042 23.9492921,5.91084438 C23.9492921,5.20661833 24.5316404,4.63572816 25.2500007,4.63572816 Z" />
				<path d="M19.4938478,24.8196585 C19.3595522,24.0644744 18.7458304,23.4899264 17.9389609,23.3640196 C15.6064348,23 11.718787,23 10.9413435,23 C10.1639,23 6.27625217,23 3.94372609,23.3640196 C3.13685652,23.4899264 2.52313478,24.0644744 2.38883913,24.8196585 C2,27.0032999 2,30.6428 2,31.3706194 C2,32.0984389 2,35.7379389 2.38883913,37.9215804 C2.52313478,38.6767645 3.13685652,39.2513125 3.94372609,39.3772192 C6.27625217,39.7412389 10.1639,39.7412389 10.9413435,39.7412389 C11.718787,39.7412389 15.6064348,39.7412389 17.9389609,39.3772192 C18.7458304,39.2513125 19.3595522,38.6767645 19.4938478,37.9215804 C19.882687,35.7379389 19.882687,32.0984389 19.882687,31.3706194 C19.882687,29.185879 19.882687,27.0032999 19.4938478,24.8196585 Z M10.9501478,37.231859 C10.0727652,37.231859 9.57177826,36.7887859 9.57177826,35.9654649 C9.57177826,35.2374623 10.0372348,34.6675667 10.9325391,34.6675667 C11.8099217,34.6675667 12.3109087,35.1265384 12.3109087,35.9654649 C12.3109087,36.6780818 11.8454522,37.231859 10.9501478,37.231859 Z M12.9335913,30.9518705 L11.9872609,31.4403682 C11.4384957,31.7193998 11.1926,32.0682168 11.1926,32.4520548 C11.1926,32.6895082 11.2665957,32.9435563 11.3828913,33.2022568 L9.40770435,33.2022568 C9.23603913,32.9197817 9.14838696,32.594666 9.14838696,32.2252614 C9.14838696,31.4228577 9.54579565,30.8472474 10.5678043,30.2714538 L11.5707174,29.7133906 C12.3657304,29.2772777 12.6305652,28.9283142 12.6305652,28.3700677 C12.6305652,27.7770934 12.0250609,27.3410172 10.8326391,27.3410172 C9.96194783,27.3410172 9.12925217,27.4980618 8.20221304,27.8293317 L8.20221304,26.0674431 C9.24276957,25.6837883 10.2080391,25.5093798 11.3817565,25.5093798 C13.5389,25.5093798 14.9394174,26.5559408 14.9394174,28.1259105 C14.9394174,29.4865606 14.3908478,30.2018883 12.9335913,30.9518705 Z" />
			</g>
		</g>
	</svg>
);

import React from 'react';
import styled from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { Scroll, ScrollProps } from '@dop/ui-primitives/positioning/Scroll';
import { Box, BoxProps } from '@dop/ui-primitives/planeDivision/Box';

const ScrollInlineShadows = styled.span<{ $gridArea?: CSSProperty.GridArea }>`
	--scroll-shadow-size: calc(3 * var(--root-cap));
	--scroll-shadow-stops: hsla(0, 0%, 100%, 1), 70%, hsla(0, 0%, 100%, 0);

	position: absolute;
	inset: 0;

	/* Because the scroll shadows are on top of the content
     * the content has to be made clickable again
     */
	pointer-events: none;

	display: grid;
	grid-template-areas: 'children';
	grid-template-columns: minmax(0, 1fr);
	grid-template-rows: minmax(0, 1fr);

	/* Because this scroll shadow component is as wide as the
     * full scrollable content the left and right edge are only in view
     * when the scroll area is scrolled all the way to the left or right respectively.
     * This can be used to mask the shadows away when that happens.
     * A bit of a gradient is used to make the transition between visible
     * and hidden shadows a bit more seamless.
     */
	mask-image: linear-gradient(
		to right,
		transparent var(--scroll-shadow-size),
		black min(calc(1.5 * var(--scroll-shadow-size)), 50%),
		black max(calc(100% - 1.5 * var(--scroll-shadow-size)), 50%),
		transparent calc(100% - var(--scroll-shadow-size))
	);

	&:before,
	&:after {
		content: '';
		/* The shadows stay in view stuck to the start and end
         * of the scroll area.
         */
		position: sticky;
		grid-area: children;
		inline-size: var(--scroll-shadow-size);
	}

	&:before {
		justify-self: start;
		/* inset slightly to the left to overlap a visible line during scrolling */
		inset-inline-start: -1px;
		background-image: linear-gradient(to right, var(--scroll-shadow-stops));
	}

	&:after {
		justify-self: end;
		/* inset slightly to the left to overlap a visible line during scrolling */
		inset-inline-end: -1px;
		background-image: linear-gradient(to left, var(--scroll-shadow-stops));
	}
`;

const getContentInlineSize = (
	$overflowX: Exclude<ScrollProps['$overflowX'], undefined>
) => {
	return ['auto', 'scroll'].includes($overflowX) ? 'max-content' : 'unset';
};

const getContentInlineSizeMq = ($mq: ScrollProps['$mq']) => {
	if ($mq == null) return undefined;

	const contentInlineSizeMq: BoxProps['$mq'] = {};

	for (const [mqQuery, mqStyle] of Object.entries($mq)) {
		if (mqStyle.$overflowX != null) {
			contentInlineSizeMq[mqQuery] = {
				$inlineSize: getContentInlineSize(mqStyle.$overflowX),
			};
		}
	}

	return contentInlineSizeMq;
};

export type ScrollInlineProps = ScrollProps;

export const ScrollInline = React.forwardRef(
	(
		{
			children,
			$paddingBlock,
			$paddingInline,
			$overflowX = 'auto',
			$mq,
			...props
		}: ScrollInlineProps,
		ref: React.ForwardedRef<HTMLElement>
	) => {
		return (
			<Scroll
				ref={ref}
				$overflowX={$overflowX}
				$overflowY="hidden"
				$maxInlineSize="100%"
				$mq={$mq}
				{...props}
			>
				<Box
					// This element is as wide as the scrolling content
					// (as opposed to the scroll container).
					// This can be used to position absolute the scroll
					// shadow element relative to this element.
					$inlineSize={getContentInlineSize($overflowX)}
					$position="relative"
					$paddingBlock={$paddingBlock}
					$paddingInline={$paddingInline}
					$mq={getContentInlineSizeMq($mq)}
				>
					{children}
					<ScrollInlineShadows $gridArea="children" />
				</Box>
			</Scroll>
		);
	}
);

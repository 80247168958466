import React from 'react';

import { renderNodes } from '@dop/shared/components/markup/Markup';
import { IconInnovatieCircle } from '@dop/ui-icons/svg/innovatieCircle';
import { IconImportantSquare } from '@dop/ui-icons/svg/importantSquare';
import { IconRegelhulp } from '@dop/ui-icons/svg/regelhulp';
import { translate } from '@dop/shared/translate/translate';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { MarginTrim } from '@dop/ui-composites/typography/MarginTrim';

import { ValidatedElementNode } from '../markupValidate';
import { MarkupElementProps } from '../markupResolvers';

const blockTypes = {
	tip: { Icon: IconInnovatieCircle, text: translate('tip') },
	important: { Icon: IconImportantSquare, text: translate('important') },
	tool: { Icon: IconRegelhulp, text: translate('tool') },
} as const;

export const hasMarkupTipBlock = (node: ValidatedElementNode) => {
	const blockAttribute = node.attributes?.['data-dop-block'];

	return blockAttribute != null && Object.hasOwn(blockTypes, blockAttribute);
};

export const MarkupTipBlock = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps) => {
	const blockAttribute = node.attributes?.['data-dop-block'];

	if (blockAttribute == null || !(blockAttribute in blockTypes)) return null;

	const { Icon, text } = blockTypes[blockAttribute as keyof typeof blockTypes];

	return (
		<Box
			as={'aside'}
			$padding={2}
			$backgroundColor={['negative', 'secondary']}
			css={`
				margin-block: 1rem;
			`}
		>
			<Shelf $gap={2}>
				<Svg
					as={Icon}
					aria-label={text}
					$inlineSize={3}
					$blockSize={3}
					$fill={['positive', 'secondary']}
				/>
				<MarginTrim
					css={`
						margin-block-start: 0.3rem;
					`}
				>
					{renderNodes(node.children, ancestorRules, markupResolvers)}
				</MarginTrim>
			</Shelf>
		</Box>
	);
};

import React from 'react';

import { Image, ImageProps } from './Image';

export const ContentImage = ({
	alt = '',
	...props
}: Omit<ImageProps, 'loading'> &
	Partial<Pick<ImageProps, 'loading'>>): React.ReactElement | null => {
	return (
		<Image
			loading="lazy"
			{...props}
			alt={alt}
			imageSet={['content', 'medium', 'large']}
		/>
	);
};

export type ElementRule = {
	element: string;
	contentCategories?: Array<typeof elementType[keyof typeof elementType]>;
	children: {
		onlyElements?: Array<string>;
		alsoElements?: Array<string>;
		contentCategories?: Array<typeof elementType[keyof typeof elementType]>;
		forbidInteractive?: boolean;
		onlyPhrasing?: boolean;
		empty?: boolean;
	};
};

export type ElementChildrenRules = Exclude<ElementRule['children'], undefined>;

export const iframeSourceWhitelist = [
	/(?:^|\.)youtube\.com$/,
	/(?:^|\.)youtu\.be$/,
	/^quadia\.webtvframework\.com$/,
] as const;

export const classNameWhitelist = [
	// ClassNames for special markup
	// Special markup comes after filtering
	// so classNames needed for special markup
	// should not be filtered out
	'acc',
	'dop-call-to-action',
	'dop-cookiesBlock',
	'downloadLink',

	// ClassNames for CSS styling
	'u-noMargin',
	'textLine',
] as const;

export const attributeWhitelist = [
	'alt',
	'href',
	'title',
	'className',
	'target',
	'id',
	'src',
	'lang',
	'colSpan',
	'cite',
	'dateTime',
	'htmlFor',
	/^aria-[a-z0-9-]+/,
	/^data-[a-z0-9-]+/,
	'hrefLang',
	'rel',
	'rowSpan',
	'allowFullScreen',
	'scope',
	'seamless',
	'sizes',
	'span',
	'srcset',
	'type',
	'media',
	'reversed',
	'start',
] as const;

export const elementType = {
	flow: 'flow', //alles
	phrasing: 'phrasing',
	metadata: 'metadata',
	sectioning: 'sectioning',
	heading: 'heading',
	interactive: 'interactive',
	embedded: 'embedded',
} as const;

export const elementRules: Array<ElementRule> = [
	{
		element: 'p',
		contentCategories: [elementType.flow],
		children: {
			onlyElements: [],
			onlyPhrasing: true,
		},
	},
	{
		element: 'iframe',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			empty: true,
		},
	},
	{
		element: 'a',
		contentCategories: [
			elementType.flow,
			elementType.phrasing,
			elementType.interactive,
		],
		children: {
			forbidInteractive: true,
		},
	},
	{
		element: 'abbr',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'aside',
		contentCategories: [elementType.flow, elementType.sectioning],
		children: {},
	},
	{
		element: 'blockquote',
		contentCategories: [elementType.flow],
		children: {},
	},
	{
		//br is not allowed in markup except for sdg related pages. defaultMarkupResolvers handles filtering out br for normal pages.
		element: 'br',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {},
	},
	{
		element: 'caption',
		contentCategories: [],
		children: {},
	},
	{
		element: 'cite',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'code',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'col',
		contentCategories: [],
		children: {
			empty: true,
		},
	},
	{
		element: 'colgroup',
		contentCategories: [],
		children: {
			onlyElements: ['col'],
		},
	},
	{
		element: 'dd',
		contentCategories: [],
		children: {},
	},
	{
		element: 'del',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {},
	},
	{
		element: 'details',
		contentCategories: [
			elementType.flow,
			elementType.sectioning,
			elementType.interactive,
		],
		children: {
			alsoElements: ['summary'],
		},
	},
	{
		element: 'dfn',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'div',
		contentCategories: [elementType.flow],
		children: {},
	},
	{
		element: 'dl',
		contentCategories: [elementType.flow],
		children: {
			onlyElements: ['dt', 'dd'],
		},
	},
	{
		element: 'dt',
		contentCategories: [],
		children: {},
	},
	{
		element: 'em',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'h2',
		contentCategories: [elementType.flow],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'h3',
		contentCategories: [elementType.flow],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'h4',
		contentCategories: [elementType.flow],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'h5',
		contentCategories: [elementType.flow],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'h6',
		contentCategories: [elementType.flow],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'img',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			empty: true,
		},
	},
	{
		element: 'ins',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {},
	},
	{
		element: 'li',
		contentCategories: [],
		children: {},
	},
	{
		element: 'ol',
		contentCategories: [elementType.flow],
		children: {
			onlyElements: ['li'],
		},
	},
	{
		element: 'pre',
		contentCategories: [elementType.flow],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'figure',
		contentCategories: [elementType.flow, elementType.sectioning],
		children: {
			alsoElements: ['figcaption'],
		},
	},
	{
		element: 'ul',
		contentCategories: [elementType.flow],
		children: {
			onlyElements: ['li'],
		},
	},
	{
		element: 'tr',
		contentCategories: [],
		children: {
			onlyElements: ['td', 'th'],
		},
	},
	{
		element: 'thead',
		contentCategories: [],
		children: {
			onlyElements: ['tr'],
		},
	},
	{
		element: 'th',
		contentCategories: [],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'tfoot',
		contentCategories: [],
		children: {
			onlyElements: ['tr'],
		},
	},
	{
		element: 'td',
		contentCategories: [],
		children: {},
	},
	{
		element: 'tbody',
		contentCategories: [],
		children: {
			onlyElements: ['tr'],
		},
	},
	{
		element: 'table',
		contentCategories: [elementType.flow],
		children: {
			onlyElements: ['caption', 'colgroup', 'thead', 'tfoot', 'tbody'],
		},
	},
	{
		element: 'section',
		contentCategories: [elementType.flow, elementType.sectioning],
		children: {},
	},
	{
		element: 'sup',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'sub',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'strong',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'span',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'q',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
	{
		element: 'figcaption',
		contentCategories: [],
		children: {},
	},
	{
		element: 'picture',
		contentCategories: [
			elementType.flow,
			elementType.phrasing,
			elementType.embedded,
		],
		children: {
			onlyElements: ['source', 'img'],
		},
	},
	{
		element: 'source',
		contentCategories: [],
		children: {
			empty: true,
		},
	},
	{
		element: 'summary',
		// Can only be used inside a details element
		contentCategories: [],
		children: {
			forbidInteractive: true,
		},
	},
	{
		element: 'time',
		contentCategories: [elementType.flow, elementType.phrasing],
		children: {
			onlyPhrasing: true,
		},
	},
];

import React from 'react';

import { renderNodes } from '@dop/shared/components/markup/Markup';

import { getElementRule, mergeRules } from '../markupRulesFunctions';
import { MarkupError } from './MarkupError';
import { MarkupElementProps } from '../markupResolvers';

// Renders any element based on tag
export const MarkupRegularElement = ({
	node,
	ancestorRules,
	markupResolvers,
}: MarkupElementProps): React.ReactElement | null => {
	const childRules = getElementRule(node.tag)?.children;

	if (childRules == null) {
		return (
			<MarkupError>
				{`Unsupported/forbidden HTML was not rendered: ${node.tag}`}
			</MarkupError>
		);
	}

	const childrenRules = mergeRules(ancestorRules || {}, childRules);

	const content = renderNodes(node.children, childrenRules, markupResolvers);

	return React.createElement(node.tag, node.attributes, content);
};

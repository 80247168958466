import React from 'react';

import environmentConfig from 'moduleAlias/environmentConfig';
import { Box, BoxProps } from '@dop/ui-primitives/planeDivision/Box';
import { roodColorTheme } from '@dop/ui-primitives/colorTheme/globalDefinitions/roodColorTheme';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';

/**
 * Will display an error message on non-production environments when
 * special markup cannot be rendered.
 *
 * This helps the editors spot and understand errors on staging before
 * publishing to production.
 *
 * On the production environment no errors are shown.
 */
export const MarkupError = ({
	children,
	...boxProps
}: {
	children: React.ReactNode;
} & BoxProps) => {
	if (environmentConfig.env === 'productie') {
		return null;
	}

	return (
		<Box
			lang="en"
			$colorTheme={roodColorTheme}
			$backgroundColor={['positive', 'primary']}
			$padding={1}
			$inlineSize="fit-content"
			css={`
				margin-block: 0.5rem;
				break-inside: avoid;
			`}
			{...boxProps}
		>
			<TextBlock
				$fontStyle={'normal'}
				$fontWeight="bold"
				$whiteSpace="pre-wrap"
			>
				{children}
			</TextBlock>
		</Box>
	);
};
